@import 'variables';
@import 'fonts';
@import 'reset';
@import 'form';
@import 'loader';
@import 'unauthenticated';
@import 'page-header';
@import 'header';
@import 'navigation';
@import 'progress';
@import 'summary';

:root {
  --primary-color: #f40002;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.d-flex {
  display: flex;
}

.text-danger {
  color: $danger-color;
}

.text-success {
  color: $success-color;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  max-width: 768px;
  position: relative;
  width: 100%;
}

.row {
  display: flex;

  .col {
  }
}

.hidden {
  display: none;
}

.stock-list {
  li {
    display: flex;

    .description {
      flex: 2;
      display: flex;
    }

    .quantity {
      flex: 1;
      display: flex;
    }

    &.header {
      font-weight: bold;
    }

    .form-group {
      div.form-control {
        padding-left: 0;
      }
    }
  }
}

.border-bottom {
  border-bottom: 1px solid #000;
}

.border-top {
  border-top: 1px solid #000;
}

.material-list {
  li {
    > a {
      color: inherit;
      display: flex;
      padding: 0.5rem 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    .details {
      display: flex;
      width: 100%;
      align-items: flex-start;

      > div {
        padding-right: 1.5rem;
        flex: 1;

        &:last-child {
          padding-right: 0rem;
        }
      }

      .title {
        flex: 12;
      }

      .tax {
        flex: 2;
      }

      .price {
        flex: 3;
      }

      .spacer {
        flex: 10;
      }

      .label {
        flex: 6;
      }

      .actions {
        flex: 1;
        display: flex;
        min-width: 8rem;
        max-width: 8rem;
      }
    }

    &.footer {
      display: flex;
      .actions {
        margin-left: auto;
      }
    }
  }
}

canvas[app-signature-pad] {
  width: 100%;
  display: block;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
}

.modal-container {
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.modal-dialog {
  background: #fff;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  &.large-modal {
    height: 100%;
    width: 100%;
    min-width: 50%;
    max-width: 80%;
    min-height: 50%;
    max-height: 80%;
  }

  .form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .modal-header {
    height: 3rem;
  }

  .modal-body {
    height: calc(100% - 8rem);
    position: relative;
    overflow: auto;
  }

  .modal-footer {
    display: flex;
    padding-top: 0.5rem;
    height: 2.5rem;

    .actions {
      display: flex;
      margin-left: auto;
    }
  }
}

.suggestions {
  background: #fff;
  border-radius: 0.5rem;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  height: 10rem;
  overflow: auto;
  position: absolute;
  width: 100%;

  li {
    padding: 0.5rem;

    &.selected {
      background-color: $primary-color;
      color: #fff;
    }
  }
}

tna-confirm-modal {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  width: 20rem;

  .message {
    padding: 1rem 0;
  }

  .actions {
    margin-left: auto;
    display: flex;
  }
}

.icon {
  width: 16px;
}

[tna-money] {
  display: flex;

  .currency {
    width: 20px;
  }

  .amount {
    width: 100%;
    max-width: 70px;

    &.align-right {
      text-align: right;
    }
  }
}

.resend-email {
  padding-left: 1rem;
}

ul {
  &.thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin-left: -2rem;

    li {
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      height: 10rem;
      margin-bottom: 1rem;
      margin-left: 2rem;
      width: calc(33.33% - 2rem);

      .thumbnail-container {
        width: 100%;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .btn-delete {
          background: white;
          width: 1rem;
          position: absolute;
          right: 5px;
          top: 5px;
        }

        .progress-container {
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: center;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;

          .progressbar {
            width: 80%;
            overflow: hidden;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.invoice-header {
  > div {
    padding: 0.25rem;
  }
}

.add-photo {
  display: inline-block;

  input {
    display: none;
  }
}

.buttons {
  display: flex;
}

.read-only {
  .label {
    font-weight: bold;
  }
}

.timeline {
  position: relative;
  margin-bottom: 3rem;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background: $secondary-color;
    left: 5.9rem;
  }

  .timeline-item {
    position: relative;
    display: flex;
    padding: 0.5rem 0;

    &:before {
      content: '';
      position: absolute;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;
      background: #fff;
      border: 1px solid $secondary-color;
      left: 5.5rem;
      margin-top: 3px;
    }

    .date {
      width: 8rem;
    }

    .details {
      width: 100%;

      .text-right {
        align-items: flex-end;
      }

      .images-list {
        display: flex;
        flex-wrap: wrap;

        .image-container {
          a {
            display: flex;
            border: 1px solid red;
            width: 10rem;
            height: 7.5rem;
            margin-right: 1rem;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .products-list {
        margin-top: 1rem;
      }
    }
  }
}

.errors {
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  .error {
    padding: 0.5rem;
    color: $primary-color;
  }
}

.payment-row {
  justify-content: flex-start;
  [tna-money] {
    margin-right: 1rem;
  }
}

.financial-summary {
  .price {
    display: flex;
    flex-direction: row;

    [tna-money] {
      margin-left: auto;
    }
  }
}

small {
  &.deposit {
    display: inline-flex;

    .currency {
      width: auto;
    }
  }
}

app-autocomplete {
  display: block;
  position: relative;

  &.ng-touched {
    &.ng-invalid {
      border: none !important;

      input {
        border: 1px solid $primary-color !important;
      }
    }
  }
}

.no-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}

.mechanic-deposit-item {
  display: flex;
  justify-content: center;
}

.status-group {
  border-bottom: 1px solid $dark-gray;
  padding: 3rem 0;

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }

  li,
  .no-results {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.needs-approval {
      background: rgba(255, 0, 0, 0.1);
    }
  }
}

.ngx-timepicker {
  border-bottom: none !important;

  input {
    background: transparent;
  }

  .ngx-timepicker-control {
    width: 3rem !important;

    &:after {
      display: none;
    }

    .ngx-timepicker-control__input {
      text-align: left;
    }

    .ngx-timepicker-control__arrows {
      top: 5px !important;

      .ngx-timepicker-control__arrow {
        font-size: 8px;
      }
    }
  }
}

.status-group {
  border-bottom: 1px solid $dark-gray;
  padding: 3rem 0;

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }

  li,
  .no-results {
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    &.needs-approval {
      background: rgba(255, 0, 0, 0.1);
    }
  }
}
