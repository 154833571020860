app-unauthenticated-layout {
  background: $dark-gray;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;

  tna-users-login,
  tna-users-activation,
  tna-users-forgot-password {
    display: flex;
    padding-top: 10rem;

    .form {
      background: $body-bg;
      border-radius: 0.5rem;
      margin: auto;
      min-width: 20rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .header {
        background: $primary-color;
        color: #fff;
        font-size: 1.5rem;
        padding: 2rem;
        position: relative;
        text-transform: uppercase;
        text-align: center;

        &:after {
          content: '';
          display: block;
          height: 0.5rem;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.2);
        }
      }

      .body {
        padding: 2rem;

        .form-group {
          label {
            display: none;
          }
        }
      }

      .footer {
        margin-top: auto;
        background: #fff;
        padding: 1.5rem;
      }
    }
  }
}
