.form {
  .form-group {
    padding: 1rem 0;

    .label {
      display: block;
      margin-bottom: 0.5rem;
    }

    &.required {
      .label {
        &:after {
          content: ' *';
          color: $primary-color;
        }
      }
    }

    .form-control {
      background-color: #fff;
      border-radius: 0.5rem;
      border: 0.5px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      font-family: inherit;
      font-size: 1rem;
      box-sizing: border-box;
      outline: none;
      padding: 0.5rem;
      height: 2rem;
      width: 100%;
    }

    div.form-control {
      border: none;
    }

    textarea {
      &.form-control {
        height: auto;
      }
    }

    select {
      &.form-control {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .input-group {
      display: flex;

      .middleName {
        width: 80%;
        margin: 0 1rem;
      }

      .city,
      .house-number {
        max-width: 20rem;
      }

      .postal-code,
      .house-number-suffix {
        max-width: 6rem;
        margin-left: 1rem;
      }
    }

    .ng-touched {
      &.ng-invalid {
        border: 1px solid $primary-color;
      }
    }

    &.plain-text {
      .label {
        font-weight: bold;
      }

      div.form-control {
        padding-left: 0;
      }
    }
  }
}
