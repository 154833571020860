app-page-header,
.app-page-header {
  background-color: $success-color;
  display: flex;
  padding: 1rem;

  .page-title {
    display: flex;
    width: 100%;

    .container {
      flex-direction: row;

      h1 {
        color: #fff;
      }

      .actions {
        display: flex;

        .btn {
          .icon {
            margin-right: 0.5rem;
          }
        }

        .btn-primary {
          background: #fff;
          color: $success-color;
        }
      }
    }
  }
}
