app-navigation {
  background: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 3.6rem;

  ul {
    background: #fff;
    cursor: pointer;
    border: 1px solid #000;
    margin: 0.5rem auto 0 auto;
    overflow: hidden;
    height: 2.5rem;
    width: 60%;
    position: relative;
    z-index: 999;

    &.open {
      height: auto;
    }

    li {
      min-height: 2.5rem;
      line-height: 2.5rem;

      a {
        align-items: center;
        color: #000;
        display: flex;
        width: 100%;
        height: 100%;

        .icon {
          margin: 0 0.5rem;
        }
      }

      &.current-item {
        a {
          img.arrow {
            width: 1rem;
            margin-left: auto;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
