app-summary {
  left: 0;
  position: absolute;
  top: 12rem;
  width: 100%;

  .summary-container {
    align-items: center;
    background-color: #fff;
    border: 1px solid rgba($primary-color, 0.5);
    box-sizing: border-box;
    display: flex;
    padding: 1rem;
    height: 9rem;
    width: 70%;
    margin: auto;
  }
}
