app-progress {
  display: contents;

  .container {
    background: #000;
    box-sizing: border-box;
    width: 100%;
    padding: 3.5rem 1rem;

    ul {
      display: flex;

      li {
        width: 100%;
        height: 2.5rem;
        position: relative;
        margin-right: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        @for $i from 1 through 10 {
          &:nth-child(#{$i}) {
            z-index: #{10 - $i};
          }
        }

        .icon {
          padding: 0.5rem;

          img {
            display: none;
          }
        }

        &:before,
        &:after {
          content: '';
          width: 1.75rem;
          height: 1.75rem;
          background-color: #000;
          position: absolute;
          top: 0;
          left: 0;
          transform: translateX(-50%) translateY(20%) rotate(45deg);
        }

        &:after {
          left: auto;
          right: -1.75rem;
        }

        &.done {
          background-color: green;

          .icon {
            .check {
              display: block;
            }
          }

          &:after {
            background-color: green;
          }
        }

        &.todo {
          background-color: $primary-color;

          .icon {
            .circle {
              display: block;
            }
          }

          &:after {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}
