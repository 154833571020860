app-header {
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  position: relative;
  width: 100%;

  .logo-container {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 8rem auto 6rem auto;
    width: 80%;
  }

  .logout {
    border-radius: 2rem;
    border: 1px solid #fff;
    color: #fff;
    padding: 0.1rem 1rem;
    position: absolute;
    right: 0;
    top: 1rem;
  }

  .version {
    color: #fff;
    padding: 0.1rem 1rem;
    position: absolute;
    right: 0;
    bottom: 1rem;
    font-size: 0.8rem;
  }

  app-queue-button {
    background: red;
    border-radius: 2rem;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem 1rem;
    position: absolute;
    left: 0;
    bottom: 1rem;
    font-size: 0.8rem;
    width: 6rem;
    height: 1.25rem;

    .loader {
      padding: 0;
      margin: 0;
    }
  }

  app-back-button {
    border-radius: 2rem;
    border: 1px solid #fff;
    color: #fff;
    padding: 0.1rem 1rem;
    position: absolute;
    left: 0;
    top: 1rem;

    a {
      color: inherit;
    }
  }
}
