.loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem;

  img {
    max-width: 10rem;
  }
}

.btn .loader {
  padding: 0;
}
