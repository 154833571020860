html,
body {
  background-color: #fff;
  font-family: 'PT Sans';
  font-size: 14px;
  color: $dark-gray;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

a {
  color: $primary-color;
  cursor: pointer;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
}

h1,
h2,
h3 {
  padding: 0;
  margin: 0;
  font-weight: 300;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.1rem;
}

.px-2 {
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.pt-2 {
  padding-top: 1.25em;
}

.py-2 {
  padding-top: 1.25em;
  padding-bottom: 1.25em;
}

.p-1 {
  padding: 1em;
}

.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.p-2 {
  padding: 1.25em;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 1.25rem;
}

.mt-3 {
  margin-top: 2rem !important;
}

.mb-3 {
  margin-bottom: 2rem !important;
}

.mb-1 {
  margin-bottom: 1.25rem;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.ml-1 {
  margin-left: 1rem !important;
}

.main-title {
  margin-bottom: 1rem;
}

.card {
  background-color: #fff;
  border-radius: 0.25rem;
  position: relative;
  overflow: hidden;

  .header {
    display: flex;
    border-bottom: none;
  }

  .body {
    display: flex;
    flex-direction: column;
  }
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .col {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.col-80 {
      width: 80%;
    }

    &.col-75 {
      width: 75%;
    }

    &.col-65 {
      width: 65%;
    }

    &.col-50 {
      width: 50%;
    }

    &.col-48 {
      width: 48%;
    }

    &.col-45 {
      width: 45%;
    }

    &.col-40 {
      width: 40%;
    }

    &.col-35 {
      width: 35%;
    }

    &.col-32 {
      width: 32%;
    }

    &.col-30 {
      width: 30%;
    }

    &.col-25 {
      width: 25%;
    }

    &.col-24 {
      width: 24%;
    }

    &.col-20 {
      width: 20%;
    }
  }
}

.btn {
  align-items: center;
  border-radius: 0.5rem;
  border: none;
  color: #fff;
  display: flex;
  font-size: 1rem;
  padding: 0.5rem 1rem;

  &:disabled {
    opacity: 0.5;
  }

  &.btn-primary {
    background: $primary-color;
  }

  &.btn-secondary {
    background: $secondary-color;
  }

  &.btn-success {
    background: $success-color;
  }

  &.btn-sm {
    padding: 0.35rem 0.75rem;
  }

  &.btn-block {
    width: 100%;
    min-height: 3rem;
    text-transform: uppercase;
  }

  &.btn-xxl {
    height: 8rem;
    justify-content: center;
  }

  &.btn-icon {
    img {
      width: 1rem;
      height: 1rem;
      object-fit: contain;
    }
  }

  &.add-btn {
    display: inline-block;
  }

  &.btn-outline {
    background: transparent;

    &.btn-primary {
      color: $primary-color;
      border: 1px solid $primary-color;
    }

    &.btn-secondary {
      color: $secondary-color;
      border: 1px solid $secondary-color;
    }
  }

  .text {
    margin: auto;
  }

  .loader {
    margin: auto;
    display: block;
    max-width: 8rem;
  }
}

.bold {
  font-weight: bold;
}

.muted {
  color: #666;
}

.badges {
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 20rem;
}

.badge {
  border-radius: 0.5rem;
  background-color: #eee;
  color: #fff;
  padding: 0 0.5rem;
  width: fit-content;
  margin-right: 0.5rem;

  &.badge-completed {
    background-color: #28a745;
  }

  &.badge-paid {
    background-color: #28a745;
  }

  &.badge-pin {
    background-color: #e2660e;
  }

  &.badge-cash {
    background-color: #17a2b8;
  }

  &.badge-on-account {
    background-color: #2da3ed;
  }
}

ul {
  &.list {
    li {
      display: flex;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      .details {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .actions {
        margin-left: auto;

        &.actions-vertical {
          .btn {
            margin: 0.5rem 0;
          }
        }

        &.actions-horizontal {
          flex-direction: column;

          a:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }

      > a,
      > div {
        color: inherit;
        cursor: pointer;
        display: flex;
        width: 100%;

        .actions {
          display: flex;
          margin-left: auto;
          align-items: center;

          a {
            margin-left: 1rem;
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
